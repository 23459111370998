import React from "react"
import PageTemplate from '../components/pageTemplate'
import ProductDisplay from '../components/product-display'
import { graphql } from 'gatsby'

export default ({data}) => (
  <PageTemplate title="Raybek Cheese Range" img={data.fileName}>
    <div className="text-center">
      <h5>Raybek Foods supplies a wide range of mainstream and specialty cheeses in both bulk and retail pack sizes.</h5>
      <p>From Feta and Swiss slices to Pecorino Pepato, Raybek Foods can service your outlet or café today!</p>
    </div>
    <ProductDisplay products={data.cheeses}/>
    <h4>Other Cheeses</h4>
    <ul>
      <li>SHREDDED CHEDDAR PLUS  2KG</li>
      <li>SHREDDED MONZARELLA  2KG</li>
      <li>PIZZA BLEND  2KG</li>
      <li>SHREDDED PARMESAN 2KG</li>
      <li>SHAVED PARMESAN  1KG</li>
      <li>GRATED PARMESAN  2KG</li>
      <li>TASTY CHEDDAR SLICES  1.5KG</li>
      <li>SWISS CHEESE SLICES  1.5KG</li>
      <li>MILD CHEDDAR  20KG</li>
      <li>MATURE CHEDDAR  20KG</li>
      <li>ROMANO CHEESE WHEEL </li>
      <li>PECORINO CHEESE WHEEL</li>
      <li>PECORINO PEPPATO CHEESE WHEEL</li>
      <li>FETA CHEESE  13KG</li>
      <li>FETA CHEESE DICED  2KG</li>
      <li>FETA CHEESE 1KG</li>
      <li>FETA CHEESE  2KG</li>
      <li>FETA CHEESE  6*200g</li>
      <li>RICOTTA CHEESE  1KG</li>
      <li>DANISH FETA IN BRINE 2KG</li>
      <li>DANISH FETA IN BRINE 5KG</li>
      <li>PERSIAN STYLE FETA 2KG</li>
      <li>250g SHAVED PARMESAN CHEESE 16 X 250g</li>
      <li>200g GREEK FETA 6 X 200G</li>
      <li>500g SHREDDED MOZZARELLA CHEESE 8 x 500g</li>
      <li>500G SHREDDED TASTY CHEESE 8 X 500G</li>
      <li>225g CYPRIANI   </li>
      <li>HALLUMI 12 X 225G</li>
      <li>250g SLICED TASTY CHEESE 18 X 250g</li>
      <li>500G BLOCK TASTY CHEESE 8 X 500g</li>
    </ul>
  </PageTemplate>
)

export const query = graphql`
  query {
    fileName: file(relativePath: { eq: "cheese-banner.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cheeses: allPrismicProducts(filter: {data: {product_type: {eq: "cheese"}}}) {
      edges {
        node {
          ...ProductInfo
        }
      }
    }
  }
`
